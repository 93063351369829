import React from "react"

import NavBar from "../components/NavBar"
import Footer from "../components/Footer"
import Metatags from "../components/metatags"

const AboutUs = (location) => {

  const url = `https://speedcambrasil.com.br${location.pathname}`

  return (
    <>
      <Metatags
        title="Speedcam Brasil - Sobre Nós"
        description="Speed Cam - Rental House"
        url={url}
        image="https://speedcambrasil.com.br/logo.png"
        keywords="speed cam, rental, Phanton, Alexa Mini, Alexa 35, Innovision HD, Innovision HR, Cinemagic Revolution, Laowa 12, Laowa 24"
        language="pt-br"
      />
      <NavBar />
      <div className="container mx-auto text-center">
        <h1 className="text-4xl m-4 md:text-6xl font-semibold text-sc-yellow">
          <i>Sobre nós</i>
        </h1>
        <div className="h-20" />
        <div className="container mx-auto text-xl m-4">
          <div className="grid grid-cols-12 gap-4 text-left">
            <div className="col-span-3" />
            <div className="col-span-6">
              <p className="my-5">
                Desde a mudança da película para o digital o cinema buscava uma
                solução high speed para cinema e publicidade no mundo.
              </p>
              <p className="my-5">
                Foi quando a VISION RESEARCH, fabricante número um no mundo de
                câmeras de alta velocidade voltadas para pesquisa desenvolveu a
                câmera PHANTOM FLEX 4K, viabilizando a captação de até 1000 FPS.
              </p>
              <p className="my-5">
                Em 2015 percebendo a demanda do mercado brasileiro, nasceu a
                SPEEDCAM BRASIL trazendo PHANTOM FLEX 4K, uma ferramenta que
                capacita o mercado e impulsiona a imaginação dos cineastas e
                publicitários brasileiros que tem a tradição de grandes
                premiações por sua criatividade.
              </p>
              <p className="my-5">
                Assim como a câmera especifica temos as lentes especiais
                CINEMAGIC e INNOVISION exclusividade da SPEEDCAM BRASIL.
              </p>
              <p className="my-5">
                Somos uma locadora especializada em câmeras de alta velocidade e
                lentes especificas. Atendemos os mercados de publicidade, ficção
                e industrial.
              </p>
              <p className="my-5">Aguardamos o seu contato!</p>
            </div>
          </div>
        </div>
      </div>
      <div className="h-10" />

      <Footer />
    </>
  )
}

export default AboutUs
